<style lang="scss">
#attendance-top-bard {
  margin-top: 10px;
  .card {
    .card-body {
      padding: 10px;
      display: flex;

      a {
        margin-right: 10px;
      }

      .flex__row {
        display: flex;
        align-items: center;
        min-width: 0;
        .btn:hover {
          background: rgb(235, 235, 235);
        }
        a {
          i {
            color: black;
            text-align: center;
          }
          padding: 0;
          line-height: 35px;
        }
        h2 {
          line-height: 38px;
        }
      }
    }
  }
}
</style>
<template>
  <fieldset>
    <div id="attendance-top-bard">
      <div class="card mb-5">
        <div class="card-body">
          <a class="btn btn-icon p-0" id="today-button" href=""
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="icon"
              width="24"
              height="24"
            >
              <path
                d="M22,2 C23.104,2 24,2.896 24,4 L24,22 C24,23.104 23.104,24 22,24 L2,24 C0.896,24 0,23.104 0,22 L0,4 C0,2.896 0.896,2 2,2 L4,2 L4,4 L2,4 L2,22 L22,22 L22,4 L19,4 L19,6 L17,6 L17,0 L19,0 L19,2 L22,2 Z M16,2 L16,4 L7,4 L7,6 L5,6 L5,0 L7,0 L7,2 L16,2 Z"
              ></path>
              <text
                x="12"
                y="18"
                text-anchor="middle"
                font-size="14"
                font-weight="bold"
              >
                5
              </text>
            </svg></a
          >

          <div class="flex__row">
            <div class="ml-auto">
              <a
                class="btn btn-sm"
                href="/my-attendance/statements/month/2021/03/04"
              >
                <i class="flaticon2-left-arrow"></i> </a
              ><a
                class="btn btn-sm"
                href="/my-attendance/statements/month/2021/05/04"
              >
                <i class="flaticon2-right-arrow"></i
              ></a>
            </div>
            <h2 class="date-switcher__date pl-2">April 2021</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <router-view></router-view>
      </div>
      <div class="col-lg-4">
        <v-punch-status></v-punch-status>
        <v-expected-attendace></v-expected-attendace>
        <v-shift-detail></v-shift-detail>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
// import Legend from "@/includes/content/widgets/Legend";
import PunchStatus from "@/pages/attendance/employee/punchstatus";
// import attendanceTable from "@/pages/attendance/employee/table";
import expectedAttendance from "@/pages/attendance/employee/expectedattendance";
import shiftDetail from "@/pages/attendance/employee/shiftdetails";

// import { mapGetters } from "vuex";
export default {
  components: {
    // "v-legend": Legend,
    "v-punch-status": PunchStatus,
    // "v-attendace-table": attendanceTable,
    "v-expected-attendace": expectedAttendance,
    "v-shift-detail": shiftDetail
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Attendance" }]);
  }
};
</script>
