<style lang="scss">
.punch-status {
  .card-body {
    padding: 1rem !important;
  }
  .punch-det {
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;

    // margin-bottom: 20px;
    padding: 10px 15px;
  }

  .stats-box {
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    padding: 5px;
  }
  .punch-btn-section {
    margin-bottom: 20px;
    text-align: center;
    .punch-btn {
      border-radius: 50px;
      line-height: 30px;
      border-left: 1px solid #a9a9a9;
    }
  }
}
</style>
<template>
  <div class="card punch-status mb-5">
    <div class="card-body">
      <data-loader
        :type="'dark'"
        :padding="10"
        v-if="loader"
        class="text-center"
      ></data-loader>
      <div class="punch-btn-section" v-else>
        <p>
          <span>{{ getTodayAttendance.date }}</span>
        </p>
        <p>
          <span>{{ getTodayAttendance.day_name }}</span>
        </p>
        <v-digital-clock></v-digital-clock>
        <button
          type="button"
          @click="checkInOut()"
          class="btn btn-success punch-btn"
          v-if="
            getTodayAttendance.status == null ||
              getTodayAttendance.status == 'in'
          "
        >
          <i class="flaticon-signin"></i>
          {{ getTodayAttendance.status == null ? "Punch In" : "Punch Out" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  FETCH_TODAY_ATTENDANCE,
  STORE_CHECK_IN_OUT
} from "@/core/services/store/actions.type";
import dataLoader from "@/includes/content/DataLoader";
import {
  SET_TODAY_ATTENDANCE,
  SET_ATTENDANCE
} from "@/core/services/store/mutations.type";
import DigitalClock from "@/includes/content/widgets/DigitalClock";
export default {
  data() {
    return {
      loader: false
    };
  },
  components: {
    "data-loader": dataLoader,
    "v-digital-clock": DigitalClock
  },

  computed: {
    ...mapGetters(["getTodayAttendance"])
  },
  mounted() {
    this.getTOdaySelfAttendance();
  },
  methods: {
    getTOdaySelfAttendance() {
      this.loader = true;
      this.$store
        .dispatch(FETCH_TODAY_ATTENDANCE)
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkInOut() {
      this.loader = true;
      this.$store
        .dispatch(STORE_CHECK_IN_OUT)
        .then(data => {
          this.loader = false;
          this.$store.commit(SET_TODAY_ATTENDANCE, data.data);
          this.$store.commit(SET_ATTENDANCE, {
            data: data.data,
            status: true
          });
          console.log(data.data);
        })
        .catch(errors => {
          this.loader = false;
          console.log(errors);
        });
    }
  }
};
</script>
