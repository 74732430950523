<style lang="scss">
#clock {
  //   font-family: "Share Tech Mono", monospace;
  //   color: #ffffff;

  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  //   color: #daf6ff;
  //   text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
  text-align: center;
  .time {
    letter-spacing: 0.05em;
    font-size: 50px;
    padding: 5px 0;
  }
}
</style>
<template>
  <div id="clock">
    <p class="time">{{ time }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: "",
      timerID: setInterval(this.updateTime, 1000)
    };
  },

  created() {
    this.updateTime();
  },

  methods: {
    updateTime() {
      var cd = new Date();
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ":" +
        this.zeroPadding(cd.getMinutes(), 2) +
        ":" +
        this.zeroPadding(cd.getSeconds(), 2);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    }
  }
};
</script>
